import * as React from 'react'
import { Link } from 'gatsby'

type Props = {
	to: string
	children: React.ReactNode
}

const MenuLinkItem = ({ to, children }: Props) => {
	const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

	return (
		<li className={`${pathname === to ? 'active-menu-item' : ''}`}>
			<Link to={to}>{children}</Link>
		</li>
	)
}

export default MenuLinkItem
