import * as React from 'react'
import { Link } from 'gatsby'
import '../scss/header.scss'

import ISI from '../components/ISI'

import { useAppContext } from '../context/AppContext'
import { useLayoutEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import MenuLinkItem from '../components/MenuLinkItem'
import InterstitialModal from '../components/Modals/InterstitialModal'
import ExternalLink from '../components/ExternalLink'

import ExkivityLogo from '../images/svg/Exkivity-Logo.svg'

type Props = {
	children: React.ReactNode
}

const Layout = ({ children }: Props) => {
	const { contextData, updateState } = useAppContext()

	const ISIRef = React.createRef<IISI>()
	const [showMenu, setShowMenu] = useState(false)
	const [showResourcesSubMenu, setShowResourcesSubMenu] = useState(false)
	const [subMenuWidth, setSubMenuWidth] = useState(0)

	const updateSize = () => {
		const subMenu = document.getElementsByClassName('submenu')
		const parentRect = document
			.getElementById('menu-item-resources')
			.getBoundingClientRect()
		if (subMenu && subMenu[0] && parentRect) {
			subMenu[0].style.width = parentRect.width + 'px'
		}
	}

	useLayoutEffect(() => {
		window.addEventListener('resize', updateSize)
		updateSize()
		return () => window.removeEventListener('resize', updateSize)
	}, [])

	const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

	return (
		<>
			<Helmet
				bodyAttributes={{
					class: `${showMenu ? '' : ''}`,
				}}
			/>
			{/*
			<a
				className='fixed skip-to-content-link bg-mediumVioletRed text-white py-3 px-5 left-0 translate'
				href='#main'
			>
				Skip to content
			</a>
			*/}

			<header
				className={`${
					showMenu ? 'h-screen' : ''
				} flex relative flex-col lg:flex-row lg:h-auto`}
			>
				<div className='hidden lg:block w-1/12 bg-white' />
				<div
					className={`w-12/12 lg:w-2/12 bg-white lg:skew-x-[-30deg] flex items-center lg:items-end justify-center px-2 pt-4 lg:px-0 lg:pt-0 lg:pb-[20px] ${
						pathname === '/' || pathname === '' ? '' : 'lg:rounded-br-lg'
					} lg:order-none order-1`}
				>
					<div className='lg:skew-x-[30deg] w-4/12 lg:w-10/12'>
						<Link to='/'>
							<img alt='EXKIVITY logo' src={ExkivityLogo} />
						</Link>
					</div>
					<div className='flex lg:hidden text-xs flex-grow items-center justify-end'>
						<span className='mr-3'>FOR US PATIENTS ONLY</span>
						<button
							className={`${
								showMenu ? 'rounded-full' : 'rounded-none'
							} bg-lightGrey text-mediumVioletRed`}
							onClick={() => setShowMenu(!showMenu)}
						>
							{showMenu ? (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-8 w-8'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
									strokeWidth='2'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M6 18L18 6M6 6l12 12'
									/>
								</svg>
							) : (
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-8 w-8'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
									strokeWidth='2'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M4 6h16M4 12h16M4 18h16'
									/>
								</svg>
							)}
						</button>
					</div>
				</div>
				<div className='lg:hidden order-2 text-mediumVioletRed font-bold text-center text-xs my-1'>
					<ExternalLink
						href='https://www.exkivityhcp.com/'
						target='_blank'
						rel='noreferrer'
					>
						Healthcare Professional Site
					</ExternalLink>
				</div>
				<div className='lg:hidden order-2 z-50'>
					<ul className='flex bg-lightGrey font-bold text-center text-xs'>
						<li className='py-3 flex items-center  justify-center px-2 w-1/3 border-r border-r-darkGrey'>
							<a
								href='https://content.takeda.com/?contenttype=pi&product=exkivity&language=eng&country=usa&documentnumber=1'
								target='_blank'
								rel='noreferrer'
							>
								Prescribing Information
							</a>
						</li>
						<li className='py-3 flex items-center justify-center px-2 w-1/3 border-r border-r-darkGrey'>
							<a
								href='https://content.takeda.com/?contenttype=patientinfo&product=exkivity&language=eng&country=usa&documentnumber=1'
								target='_blank'
								rel='noreferrer'
							>
								Patient Information
							</a>
						</li>
						<li className='py-3 flex items-center  justify-center px-2 w-1/3'>
							<button
								onClick={() => ISIRef.current?.toggleISIStatus()}
								className='ISI-btn font-bold inline'
							>
								Important Safety Information
							</button>
						</li>
					</ul>
				</div>
				<div
					className={`flex lg:block flex-col-reverse lg:w-8/12 lg:skew-x-[-30deg] lg:order-none order-2 ${
						showMenu ? 'expanded' : ''
					}`}
					id='header-middle-wrapper'
				>
					<div className=''>
						<ul
							className='flex flex-col lg:flex-row lg:text-center lg:text-xs lg:font-bold text-white lg:text-darkGrey tracking-wider'
							id='top-menu'
						>
							<li className='order-1'>
								<ExternalLink
									href='https://www.exkivityhcp.com/'
									target='_blank'
									rel='noreferrer'
								>
									Healthcare Professional Site
								</ExternalLink>
							</li>
							<li className='order-3 lg:order-2'>
								<a
									href='https://content.takeda.com/?contenttype=pi&product=exkivity&language=eng&country=usa&documentnumber=1'
									target='_blank'
									rel='noreferrer'
								>
									Prescribing Information
								</a>
							</li>
							<li className='order-2 lg:order-3'>
								<a
									href='https://content.takeda.com/?contenttype=patientinfo&product=exkivity&language=eng&country=usa&documentnumber=1'
									target='_blank'
									rel='noreferrer'
								>
									Patient Information
								</a>
							</li>
							<li className='relative order-4' id='important-safety-information-btn'>
								<button
									onClick={() => ISIRef.current?.toggleISIStatus()}
									className='ISI-btn lg:font-bold tracking-wider inline'
								>
									Important Safety Information
								</button>
							</li>
							<li className='relative order-5' id='register-for-updates-btn'>
								<Link to='/register' className='relative z-50'>
									Register for Updates
								</Link>
							</li>
						</ul>
					</div>
					<div className='hidden lg:block self-end text-xs lg:skew-x-[30deg] text-darkGrey uppercase lg:normal-case lg:text-right my-2 2xl:mr-7 font-bold'>
						FOR US PATIENTS ONLY
					</div>
					<nav className='lg:mb-[10px]'>
						<ul
							className='flex flex-col lg:flex-row lg:text-center lg:h-[60px] text-white lg:text-2xl lg:text-base lg:font-bold bg-mediumVioletRed'
							id='menu'
						>
							<li
								className={`${
									pathname === '/' || pathname === ''
										? '!bg-goldenFizzGreen h-[70px] rounded-br-lg'
										: ''
								} !flex-grow-0 w-[25px] hidden lg:block`}
							/>
							<li
								className={`${
									pathname === '/' || pathname === '' ? 'active-menu-item' : ''
								} lg:!hidden`}
							>
								<Link to='/'>HOME</Link>
							</li>
							<MenuLinkItem to='/egfr-exon20'>
								EGFR <br />
								EXON20
							</MenuLinkItem>
							<MenuLinkItem to='/about-exkivity'>
								ABOUT <br />
								EXKIVITY
							</MenuLinkItem>
							<MenuLinkItem to='/study-results'>
								STUDY <br />
								RESULTS
							</MenuLinkItem>
							<MenuLinkItem to='/taking-exkivity'>
								TAKING <br />
								EXKIVITY
							</MenuLinkItem>
							<MenuLinkItem to='/possible-side-effects'>
								POSSIBLE <br />
								SIDE EFFECTS
							</MenuLinkItem>
							<li
								className={`${
									pathname === '/resources' ? 'active-menu-item' : ''
								} relative`}
								id='menu-item-resources'
								onMouseEnter={updateSize}
							>
								<Link to='/resources' className='lg:mt-[9.5px]'>
									RESOURCES
								</Link>
								<button
									id='btn-resources-caret'
									onClick={() => setShowResourcesSubMenu(!showResourcesSubMenu)}
								>
									{showResourcesSubMenu ? (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-6 w-6'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
											strokeWidth='2'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M5 15l7-7 7 7'
											/>
										</svg>
									) : (
										<svg
											xmlns='http://www.w3.org/2000/svg'
											className='h-6 w-6 lg:hidden block'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'
											strokeWidth='2'
										>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M19 9l-7 7-7-7'
											/>
										</svg>
									)}
								</button>
								<ul
									className={`${
										showResourcesSubMenu ? 'expanded' : ''
									} submenu lg:absolute hidden text-white text-left lg:px-3 top-[59px] bg-white !text-mediumVioletRed pt-3 lg:rounded-b-lg lg:left-0`}
								>
									<li>
										<Link to='/resources'>USEFUL RESOURCES</Link>
									</li>
									<li>
										<Link to='/takeda-oncology-here2assist'>
											TAKEDA ONCOLOGY HERE2ASSIST&#8482;
										</Link>
									</li>
								</ul>
							</li>
						</ul>
					</nav>
				</div>
				<div className='hidden lg:flex w-1/12 justify-end mb-[10px]'>
					<div className='bg-mediumVioletRed h-[60px] -ml-[60px] flex-grow self-end' />
				</div>
			</header>

			<div id='main' className='mt-[-10px] '>
				{children}
			</div>

			<ISI ref={ISIRef} />

			{contextData.interstitialModal.isOpen && (
				<InterstitialModal url={contextData.interstitialModal.url} />
			)}

			<div className='z-[10000] fixed bottom-0 left-0 right-0 h-5 bg-gradient-to-r from-pompadourViolet to-mediumVioletRed' />
			<div className="isi-spacer" />
		</>
	)
}

export default Layout
