import React, { MouseEventHandler } from 'react'
import { useAppContext } from '../context/AppContext'

export interface Props extends React.HTMLProps<HTMLAnchorElement> {
	href: string
	children: React.ReactNode
}

const ExternalLink = (props: Props) => {
	const { contextData, updateState } = useAppContext()

	let title =
		'You are now leaving EXKIVITY.com and entering a site that is not owned or controlled by Takeda Oncology.'
	let message =
		'Takeda Oncology makes no representation as to the accuracy of the information contained on sites we do not own or control. Takeda Oncology does not recommend and does not endorse the content on any third-party websites. Your use of any third-party websites is at your own risk and subject to the terms and conditions of use for such sites.'
	const domain = new URL(props.href).hostname

	let continueButtonText = 'Continue'

	if (domain.includes('takeda.com') || domain.includes('takedaoncology.com')) {
		title = 'You are now leaving EXKIVITY.com'
		message = 'This link will take you to other Takeda Oncology website.'
	} else if (domain.includes('exkivityhcp.com')) {
		title =
			'The site you are about to enter is intended for US healthcare professionals only.'
		message = 'Please confirm that you are a US healthcare professional.'
		continueButtonText = 'Yes, I confirm that I am a US healthcare professional'
	}

	const onAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		updateState({
			...contextData,
			interstitialModal: {
				isOpen: true,
				anchorElement: (
					<a {...props}>{props.children}</a>
				) as React.ReactNode as HTMLAnchorElement,
				title,
				message,
				continueButtonText,
			},
		})
	}

	return (
		<a onClick={onAnchorClick} {...props}>
			{props.children}
		</a>
	)
}

export default ExternalLink
