import React from 'react'
import ModalLayout from './ModalLayout'
import { useAppContext } from '../../context/AppContext'
import { navigate } from '@reach/router'

type Props = {
	url: string
}

const InterstitialModal = ({ url }: Props) => {
	const { contextData, updateState } = useAppContext()

	const ExternalLinkElement = (props) =>
		React.cloneElement(contextData.interstitialModal.anchorElement, { ...props })

	const onCancel = () => {
		updateState({
			interstitialModal: {
				isOpen: false,
			},
		})
	}

	return (
		<ModalLayout>
			<div className="flex self-center bg-center bg-no-repeat justify-center items-center w-full lg:w-2/4 xl:w-2/5 mx-auto bg-[url('../images/confirm-popup-bg.png')] rounded-lg bg-no-repeat">
				<div className=''>
					<div className='flex items-center my-10 flex-col px-5'>
						<div className='font-bold text-xl lg:text-2xl text-white text-center'>
							{contextData.interstitialModal.title}
						</div>
						<div className='text-sm lg:text-base text-white text-center mt-5'>
							{contextData.interstitialModal.message}
						</div>
					</div>
					<div className='px-6 md:px-12 pb-20'>
						<ExternalLinkElement className='py-4 px-3 md:px-6 mb-5 bg-white rounded-md flex justify-between items-center font-bold text-mediumVioletRed cursor-pointer'>
							<div>
								{contextData.interstitialModal.continueButtonText
									? contextData.interstitialModal.continueButtonText
									: 'Continue'}
							</div>
							<div>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-5 w-5'
									viewBox='0 0 20 20'
									fill='currentColor'
								>
									<path
										fillRule='evenodd'
										d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
										clipRule='evenodd'
									/>
								</svg>
							</div>
						</ExternalLinkElement>
						<button
							onClick={onCancel}
							className='cursor-pointer py-4 px-3 md:px-6 bg-white rounded-md flex justify-between items-center  font-bold text-mediumVioletRed w-full'
						>
							<div>Cancel</div>
							<div>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-5 w-5'
									viewBox='0 0 20 20'
									fill='currentColor'
								>
									<path
										fillRule='evenodd'
										d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
										clipRule='evenodd'
									/>
								</svg>
							</div>
						</button>
					</div>
				</div>
			</div>
		</ModalLayout>
	)
}

export default InterstitialModal
