import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react'
import '../scss/isi.scss'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import ExternalLink from './ExternalLink'
import { Helmet } from 'react-helmet'

const ISI = React.forwardRef<IISI>((props, ref) => {
	const [ISIStatus, setISIStatus] = React.useState('initial')
	const [ISIPosition, setISIPosition] = React.useState('fixed')
	const { windowHeight, windowWidth } = useWindowDimensions()

	const toggleISIStatus = () => {
		if (ISIStatus === 'initial' || ISIStatus === 'less') {
			setISIStatus('expanded')
		} else {
			setISIStatus('less')
		}
	}
	useImperativeHandle(ref, () => ({ toggleISIStatus }))

	const getAnchorHeight = useMemo(
		() => () => {
			if (ISIPosition === 'relative' || !windowHeight) {
				return 0
			}
			if (windowHeight) {
				if (ISIStatus === 'initial') {
					return windowHeight * 0.25
				} else if (ISIStatus === 'less') {
					return windowHeight * 0.15
				}
			}
		},
		[windowHeight, ISIStatus, ISIPosition],
	)

	/*	const [y, setY] = useState(window.scrollY)

	const handleNavigation = useCallback(
		(e) => {
			const window = e.currentTarget
			if (y > window.scrollY) {
				console.log('scrolling up')
			} else if (y < window.scrollY) {
				console.log('scrolling down')
			}
			setY(window.scrollY)
		},
		[y],
	)

	useEffect(() => {
		setY(window.scrollY)
		window.addEventListener('scroll', handleNavigation)

		return () => {
			window.removeEventListener('scroll', handleNavigation)
		}
	}, [handleNavigation])*/

	useLayoutEffect(() => {
		const maxScroll = Math.max(
			document.body.scrollHeight,
			document.body.offsetHeight,
			document.documentElement.clientHeight,
			document.documentElement.scrollHeight,
			document.documentElement.offsetHeight,
		)

		const handleScroll = () => {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop
			const { innerHeight } = window
			const anchor = document.getElementById('ISI-anchor')
			console.log('Height of window: ', innerHeight * 0.25)
			console.log(
				scrollTop,
				innerHeight,
				innerHeight + window.scrollY,
				anchor.offsetTop + anchor.offsetHeight,
				maxScroll,
			)

			if (scrollTop <= 0) {
				setISIPosition('fixed')
				setISIStatus('initial')
			} else {
				if (
					Math.ceil(innerHeight + window.scrollY) >=
					anchor.offsetTop + anchor.offsetHeight - 10
				) {
					setISIPosition('relative')
				} else {
					setISIStatus('less')
					
					setISIPosition('fixed')
				}
			}
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<React.Fragment>
			<Helmet
				bodyAttributes={{ class: (ISIStatus === 'initial')||(ISIPosition === 'fixed') ? '' : 'scrolled'  }}
			/>
			<div id='ISI-anchor' style={{ height: getAnchorHeight() }} />
			<div
				className={`${ISIStatus} ${ISIPosition} bottom-0 w-full`}
				id='ISI-container'
			>
				<div
					className='w-12/12 mx-auto bg-white h-full px-5 relative pt-3 md:rounded-t-2xl'
					id='ISI-wrapper'
				>
					{ISIPosition !== 'relative' && (
						<svg
							onClick={toggleISIStatus}
							id='ISI-caret'
							className={`${
								ISIStatus === 'expanded' ? 'up' : 'down'
							} absolute right-4 text-mediumVioletRed cursor-pointer'`}
							xmlns='http://www.w3.org/2000/svg'
							xmlnsXlink='http://www.w3.org/1999/xlink'
							width='13px'
							height='11px'
						>
							<path
								fillRule='evenodd'
								fill='rgb(184, 19, 111)'
								d='M8.232,9.359 C7.462,10.693 5.537,10.693 4.767,9.359 L1.139,3.74 C0.369,1.741 1.331,0.74 2.871,0.74 L10.128,0.74 C11.668,0.74 12.630,1.741 11.860,3.74 L8.232,9.359 Z'
							/>
						</svg>
					)}
					<div
						className={`${
							ISIStatus === 'expanded' ? 'overflow-y-scroll' : ''
						} h-full lg:pr-10`}
						id='ISI-content-wrapper'
					>
						<div className='col-span-12'>
							<div className='grid grid-cols-12'>
								<div className='col-span-12 lg:col-span-6 md:pr-5 order-1'>
									<p className='text-mediumVioletRed font-bold'>
										IMPORTANT SAFETY INFORMATION
									</p>
									<p className='text-mediumVioletRed font-bold my-2'>
										What is the most important information I should know about EXKIVITY?
									</p>
									<p>
										<strong>
											EXKIVITY may cause serious side effects, including changes in the
											electrical activity of your heart called QTc prolongation and
											Torsades de Pointes. QTc prolongation can cause irregular heartbeats
											that can be life-threatening and may lead to death.
										</strong>{' '}
										Your healthcare provider will check the electrical activity of your
										heart with a test called an electrocardiogram (ECG) and do blood tests
										to check your electrolytes before starting treatment and during
										treatment with EXKIVITY. Tell your healthcare provider right away if
										you feel dizzy, lightheaded, faint or have an irregular heartbeat.
									</p>
									<p className='text-mediumVioletRed my-2 font-bold'>
										EXKIVITY may cause serious side effects, including:
									</p>
									<ul className='list-disc list-outside marker:text-mediumVioletRed ml-5'>
										<li>
											<strong className='text-black'>Lung problems.</strong> EXKIVITY may
											cause severe lung problems that may lead to death. Symptoms may be
											similar to those symptoms from lung cancer. Tell your healthcare
											provider right away if you develop any new or worsening symptoms,
											including trouble breathing or shortness of breath, cough, chest
											pain, or fever.
										</li>
									</ul>
								</div>
								<div className='col-span-12 mt-3 md:mt-0 lg:col-span-6 lg:pl-5 order-3 lg:order-2 lg:border-l-2'>
									<div className='flex'>
										<div className='grow'>
											<p className='text-mediumVioletRed font-bold mb-2'>
												What is EXKIVITY?
											</p>
										</div>
									</div>
									<p>
										EXKIVITY is a prescription medicine used to treat adults with
										non-small cell lung cancer (NSCLC):
									</p>
									<ul className='list-disc list-outside marker:text-mediumVioletRed ml-5'>
										<li>
											that has spread to other parts of the body (metastatic) and cannot be
											removed by surgery, <strong>and</strong>
										</li>
										<li>
											has a certain abnormal epidermal growth factor receptor (EGFR) gene,{' '}
											<strong>and</strong>
										</li>
										<li>
											whose disease has worsened while on or after chemotherapy that
											contains platinum
										</li>
									</ul>
									<p className='my-2'>
										Your healthcare provider will perform a test to make sure that
										EXKIVITY is right for you.
									</p>

									<p>It is not known if EXKIVITY is safe and effective in children.</p>
									<p className='mt-2'>
										EXKIVITY is approved based on a medical study that measured how many
										people responded to treatment and for how long. There are ongoing
										studies to confirm its continued approval.
									</p>
								</div>
								<div className='col-span-12 order-2 lg-order-3'>
									<ul className='list-disc list-outside marker:text-mediumVioletRed ml-5'>
										<li>
											<strong className='text-black'>Heart problems</strong>,{' '}
											<strong>including heart failure.</strong> EXKIVITY may cause heart
											problems that may lead to death. Your healthcare provider should
											check your heart function before you start and during treatment with
											EXKIVITY. Tell your healthcare provider right away if you have any
											signs or symptoms of a heart problem, including feeling like your
											heart is pounding or racing, shortness of breath, chest pain,
											swelling of your ankles and feet, or feeling faint.
										</li>
										<li>
											<strong className='text-black'>Diarrhea.</strong> Diarrhea is common
											during treatment with EXKIVITY and may sometimes be severe. Diarrhea
											can cause you to lose too much body fluid (dehydration) and kidney
											problems. Your healthcare provider may tell you to start drinking
											more fluids and electrolytes to replace body salts or start taking
											your antidiarrheal medicines. Tell your healthcare provider right
											away if you have any loose stools or have stools more often than is
											normal for you.
										</li>
									</ul>

									<p className='mt-3'>
										The most common side effects of EXKIVITY include diarrhea, rash,
										nausea, mouth sores, vomiting, decreased appetite, infection of skin
										around nails, tiredness, dry skin, muscle or bone pain. EXKIVITY may
										affect fertility in females and males, which may affect your ability
										to have a child. Talk to your healthcare provider if this is a concern
										for you.
									</p>

									<p className='my-2'>
										These are not all the possible side effects of EXKIVITY. Call your
										doctor for medical advice about side effects. You may report side
										effects to FDA at{' '}
										<strong className='text-black'>1-800-FDA-1088</strong>.
									</p>

									<p className='text-mediumVioletRed font-bold'>
										Before taking EXKIVITY, tell your healthcare provider about all of
										your medical conditions, including if you:
									</p>
									<ul className='list-disc list-outside marker:text-mediumVioletRed ml-5'>
										<li>
											have heart problems, including a condition called long QTc syndrome
										</li>
										<li>
											have problems with your electrolytes, such as sodium, potassium,
											calcium or magnesium
										</li>
										<li>have lung or breathing problems other than lung cancer</li>
										<li>
											are pregnant or plan to become pregnant. EXKIVITY can harm your
											unborn baby.
											<p className='font-bold ml-5'>
												Females who are able to become pregnant:
											</p>
											<ul className='list-outside list-[circle] marker:text-mediumVioletRed ml-10'>
												<li>
													Your healthcare provider should do a pregnancy test before you
													start treatment with EXKIVITY. You should use an effective form of
													non-hormonal birth control during treatment and for{' '}
													<strong>1 month</strong> after your last dose of EXKIVITY. Birth
													control pills (oral contraceptives) and other hormonal forms of
													birth control may not work as well during treatment with EXKIVITY.
													Talk to your healthcare provider about birth control methods that
													might be right for you during this time. Tell your healthcare
													provider right away if you become pregnant or think you may be
													pregnant during treatment with EXKIVITY.
												</li>
											</ul>
											<p className='font-bold ml-5'>
												Males who have female partners who are able to become pregnant:
											</p>
											<ul className='list-outside list-[circle] marker:text-mediumVioletRed ml-10'>
												<li>
													You should use effective birth control during treatment and for{' '}
													<strong>1 week</strong> after your final dose of EXKIVITY.
												</li>
											</ul>
										</li>
										<li>
											are breastfeeding or plan to breastfeed. It is not known if EXKIVITY
											passes into your breast milk. Do not breastfeed during treatment with
											EXKIVITY and for <strong>1 week</strong> after your last dose of
											EXKIVITY.
										</li>
									</ul>

									<p className='my-2'>
										<strong className='text-mediumVioletRed'>
											Tell your healthcare provider about all the medicines you take
										</strong>
										, including prescription medicines and over-the-counter medicines,
										vitamins, and herbal supplements. Tell your healthcare provider if you
										take medicines for heart problems. EXKIVITY and other medicines may
										affect each other causing serious side effects.
									</p>

									<p className='text-mediumVioletRed font-bold'>
										How should I take EXKIVITY?
									</p>
									<ul className='list-disc list-outside marker:text-mediumVioletRed ml-5'>
										<li>
											Take EXKIVITY exactly as your healthcare provider tells you to take
											it.
										</li>
										<li>Take your prescribed dose of EXKIVITY 1 time each day.</li>
										<li>Take EXKIVITY with or without food.</li>
										<li>
											Swallow EXKIVITY capsules whole. Do not open, chew, or dissolve the
											contents of the capsules.
										</li>
										<li>
											<strong>Do not</strong> change your dose or stop taking EXKIVITY
											unless your healthcare provider tells you to.
										</li>
										<li>
											Your healthcare provider may change your dose, temporarily stop, or
											permanently stop treatment with EXKIVITY if you develop certain side
											effects.
										</li>
										<li>
											If you miss a dose of EXKIVITY, and it has been more than 6 hours,
											skip the dose and take your next dose at your regularly scheduled
											time the next day.
										</li>
										<li>
											If you vomit a dose of EXKIVITY, do not take an extra dose. Take your
											next dose at your regularly scheduled time the next day.
										</li>
									</ul>

									<p className='text-mediumVioletRed font-bold mt-2'>
										What should I avoid while taking EXKIVITY?
									</p>
									<ul className='list-disc list-outside marker:text-mediumVioletRed ml-5'>
										<li>
											Avoid eating grapefruit or drinking grapefruit juice during treatment
											with EXKIVITY. Grapefruit may increase the amount of EXKIVITY in your
											blood.
										</li>
									</ul>

									<p className='my-2 font-bold'>
										Please see{' '}
										<strong className='text-mediumVioletRed underline'>
											<a
												href='https://content.takeda.com/?contenttype=patientinfo&product=exkivity&language=eng&country=usa&documentnumber=1'
												target='_blank'
												rel='noreferrer'
											>
												Patient Information
											</a>
										</strong>{' '}
										in the EXKIVITY (mobocertinib) full{' '}
										<strong className='text-mediumVioletRed underline'>
											<a
												target='_blank'
												href='https://content.takeda.com/?contenttype=pi&product=exkivity&language=eng&country=usa&documentnumber=1'
												rel='noreferrer'
											>
												Prescribing Information
											</a>
										</strong>
										.
									</p>
								</div>
							</div>
						</div>

						<div className='col-span-12 flex flex-col lg:flex-row mt-3 mb-10 border-t-2 py-2 lg:pt-5'>
							<div className='md:grow'>
								All trademarks are property of their respective owners.
								<br />
								©2022 Takeda Pharmaceuticals U.S.A., Inc.
								<br />
								All rights reserved. 04/22 USO-MOB-0067
								<div className='flex flex-row underline text-mediumVioletRed border-b-2 border-t-2 py-3 divide-x-2 mt-2 text-xs md:text-sm'>
									<div className='pr-2'>
										<a
											href='https://www.takeda.com/en-us/terms-of-use'
											target='_blank'
											rel='noreferrer'
										>
											Terms of Use
										</a>
									</div>
									<div className='px-2'>
										<a
											href='https://www.takeda.com/privacy-notice'
											target='_blank'
											rel='noreferrer'
										>
											Privacy Policy
										</a>
									</div>
									<div className='px-2'>
										<Link to='/sitemap'>Sitemap</Link>
									</div>
									<div className='pl-2'>
										<a
											href='https://www.takeda.com/en-us/who-we-are/contact-us'
											target='_blank'
											rel='noreferrer'
										>
											Contact Us
										</a>
									</div>
								</div>
							</div>
							<div className='mt-10 lg:mt-0 w-full lg:w-3/5 flex justify-center lg:items-end'>
								<a
									href='https://www.takedaoncology.com/'
									target='_blank'
									rel='noreferrer'
									className='w-2/3 md:w-1/3 lg:ml-auto'
								>
									<StaticImage src='../images/Takeda-logo.png' alt='Takeda logo' />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
})

ISI.displayName = 'ISIComponent'

export default ISI
