import * as React from 'react'

type Props = {
	children: React.ReactNode
	onClose?: () => void
}

const ModalLayout = (props: Props) => {
	return (
		<div className='flex p-2 fixed h-full z-[10000000] top-0 left-0 right-0 bg-black/80 overflow-x-hidden'>
			{props.children}
		</div>
	)
}

export default ModalLayout
